import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { nl.nickkoepr.site.pages.HomePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.experience.ExperienceTitleStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.footer.CopyrightTextStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.footer.SmallFooterTextStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.link.DefaultLinkStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.list.HorizontalUlStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.list.HorizontalLiStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.list.VerticalLiStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.navigation.NavigationItemStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.projects.card.ProjectCardTitleStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.projects.card.ProjectCardDescriptionStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.projects.card.BigProjectCardLinkStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.projects.card.SmallProjectCardLinkStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.text.PageTitleStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.text.SectionTitleStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.text.introduction.HelloMessageStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.components.text.introduction.IntroductionMessageStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.pages.HomePageStyle)
        ctx.theme.registerComponentStyle(nl.nickkoepr.site.styling.RootStyle)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "NickKoepr"))
    renderComposable(rootElementId = "root") {
        nl.nickkoepr.site.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
